<template>
  <div class="h-100 w-100 d-flex justify-center align-center">
    <v-sheet
      color="white"
      elevation="1"
      rounded
      width="400px"
      class="ma-5 pa-5"
    >
      <div class="text-center text-subtitle1 font-weight-medium">
        <img src="@/assets/images/fli_logo.png" class="welcome-image mb-2" alt="" srcset="" @click="$router.go('/')">
      </div>
      <v-form ref="form" @submit.prevent="routeNavigate">
        <v-text-field
          v-model="orderNo"
          ref="orderNo"
          :label="$_strings.home.PLACEHOLDER_ORDER_NO"
          outlined
          clearable
          dense
          :rules="rules.orderNo"
          class="mt-7"
          persistent-hint
        ></v-text-field>
        <v-btn class="primary w-100" type="submit">
          {{$_strings.home.TRACK}}
        </v-btn>
      </v-form>
    </v-sheet>
  </div>
</template>

<script>

export default {
  name: 'Login-form',
  props: {
    getTrackingData: Object,
  },
  data() {
    return {
      orderNo: '',
      rules: {
        orderNo: [
          (v) => !!v || this.$_strings.messages.validation.REQUIRED('Nomor Pesanan/Kiriman'),
        ],
      },
      formHasErrors: false,
    };
  },
  methods: {
    async routeNavigate() {
      const valid = this.$refs.form.validate();
      if (!valid) return;
      try {
        this.$root.$loading.show();
        const data = await this.$_services.tracking.fetchOrder(this.orderNo);
        this.$router.push({
          name: 'order-detail',
          params: {
            orderNo: this.orderNo,
            data: data.data,
          },
        });
      } finally {
        this.$root.$loading.hide();
      }
    },
  },

};
</script>

<style scoped lang="scss">
  .welcome {
    &-image {
      cursor: pointer;
      width: 229px;
      height: 70px;
    }
  }
</style>
